import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import './App.css';
import logoWebm from './assets/wurstcoin-rotation.webm';
import logoStatic from './assets/wurstcoin_512.png';


class App extends React.Component<any, any> {
  public render = () => {
    return (
      <div className="App">
        <Container className="p-3">
          <Row className='nav-row'>
            <Col className='logo'>
              <video className="video-center" loop muted autoPlay={true} preload='auto'>
                <source src={logoWebm} type="video/webm" />
                <img src={logoStatic} alt="logo" />
              </video>
            </Col>
          </Row>

          <Row>
            <Container className="p-5 mb-4 rounded-3">
              <h1 className="header">Buy your DeFi Wurst on the blockchain today</h1>
              <p>and help shape the future of WURST</p>
            </Container>
          </Row>
          <Row>
            <Container className="p-5 mb-4 rounded-3">
              <Button href='' target='_blank'>
                PinkSale LaunchPad (ETH)
              </Button>
              <br />
              <br />
              <Button href='' target='_blank'>
                PinkSale LaunchPad (Matic)
              </Button>
            </Container>
          </Row>
        </Container>
      </div >
    );
  }
}

export default App;
